export const env = {
  DEV: {
    API_URL: `https://api.lo.nftydoor.com/dev`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev`,
  },
  DANTE: {
    API_URL: `https://api.lo.nftydoor.com/dev-dante`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-dante`,
  },
  FRAN_CAJAL: {
    API_URL: `https://api.lo.nftydoor.com/dev-fran`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-fran`,
  },
  ROQUE: {
    API_URL: `https://api.lo.nftydoor.com/dev-roque`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-roque`,
  },
  LEO: {
    API_URL: `https://api.lo.nftydoor.com/dev-leo`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-leo`,
  },
  LU: {
    API_URL: `https://api.lo.nftydoor.com/dev-lu`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-lu`,
  },
  JUSTO: {
    API_URL: `https://api.lo.nftydoor.com/dev-justo`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-justo`,
  },
  LEANDRO: {
    API_URL: `https://api.lo.nftydoor.com/dev-leandro`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-leandro`,
  },
  FRANCO: {
    API_URL: `https://api.lo.nftydoor.com/dev-franco`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-franco`,
  },
  BRUNO: {
    API_URL: `https://api.lo.nftydoor.com/dev-bruno`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-bruno`,
  },
  JOAQUIN: {
    API_URL: `https://api.lo.nftydoor.com/dev-joaquin`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-joaquin`,
  },
  NACHO: {
    API_URL: `https://api.lo.nftydoor.com/dev-nacho`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-nacho`,
  },
  WALDEMAR: {
    API_URL: `https://api.lo.nftydoor.com/dev-waldemar`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-waldemar`,
  },
  ALEJO: {
    API_URL: `https://api.lo.nftydoor.com/dev-alejo`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-alejo`,
  },
  SIMON: {
    API_URL: `https://api.lo.nftydoor.com/dev-simon`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-simon`,
  },
  NICOLAS: {
    API_URL: `https://api.lo.nftydoor.com/dev-nicolas`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-nicolas`,
  },
  ESTEBAN: {
    API_URL: `https://api.lo.nftydoor.com/dev-esteban`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-esteban`,
  },
  SHANE: {
    API_URL: `https://api.lo.nftydoor.com/dev-shane`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-shane`,
  },
  JUANI: {
    API_URL: `https://api.lo.nftydoor.com/dev-juani`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-juani`,
  },
  PABLOBA: {
    API_URL: `https://api.lo.nftydoor.com/dev-pabloba`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-pabloba`,
  },
  JULIAN: {
    API_URL: `https://api.lo.nftydoor.com/dev-julian`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-julian`,
  },
  MARCOS: {
    API_URL: `https://api.lo.nftydoor.com/dev-marcos`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-marcos`,
  },
  MELANY: {
    API_URL: `https://api.lo.nftydoor.com/dev-melany`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-melany`,
  },
  MATIAS: {
    API_URL: `https://api.lo.nftydoor.com/dev-matias`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-matias`,
  },
  JULIO: {
    API_URL: `https://api.lo.nftydoor.com/dev-juliochort`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-juliochort`,
  },
  LUIS: {
    API_URL: `https://vpzai83pge.execute-api.us-east-1.amazonaws.com/dev-luis`,
    API_ADMIN_URL: `https://frye7ecnp6.execute-api.us-east-1.amazonaws.com/dev-luis`,
  },
  NICOLASF: {
    API_URL: `https://api.lo.nftydoor.com/dev-nicolasf`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-nicolasf`,
  },
  GABRIEL: {
    API_URL: `https://api.lo.nftydoor.com/dev-gabriel`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/dev-gabriel`,
  },
  PROD: {
    API_URL: `https://api.lo.nftydoor.com/prod`,
    API_ADMIN_URL: `https://api.admin.nftydoor.com/prod`,
  },
};

export const ENV = localStorage.getItem("currentEnvironment") ?? "DEV";

function getEnvVariable(key) {
  const isInProduction = process.env.REACT_APP_ENV === "prod";
  return env[isInProduction ? "PROD" : ENV][key];
}

export default getEnvVariable;
